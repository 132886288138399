import TextField from "components/form/textField";
import React, { useRef } from "react";
import Button from "components/primary/button";
import useReCaptcha from "hooks/useReCaptcha";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormMessage from "./formMessage";
import useMessage from "hooks/useMessage";

const TextFieldAndButton = ({ data }) => {
  let _btnText = useRef(data?.buttonText || "Subscribe");

  // const [curBtnText, setCurBtnText] = useState(_btnText.current);
  const { message, setMessage } = useMessage();

  const initValues = { email: "" };
  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { handleSubmit } = useReCaptcha({
    onSuccess: async (values, config) => {
      config.formikOptions.setSubmitting(true);

      const headers = {
        "Content-Type": "application/json;charset=utf-8",
      };

      const body = {
        email: values.email,
        token: config.token,
        newsletter_lists: data?.newsletter_lists?.data || [],
        minimumScore: data?.minimumCaptchaScore || 0.5,
      };

      let response = await fetch("/api/subscribe-newsletter", {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });
      let responseJson = await response.json();
      console.log("response", response);

      if (response.ok) {
        config.formikOptions.setSubmitting(false);
        config.formikOptions.resetForm();
        setMessage({ text: "Thanks for subscribing!", type: "success" });
      }
      if (responseJson?.error?.message) {
        let errorMessage = responseJson.error.message.includes("unique")
          ? "email already subscribed"
          : responseJson.error.message;
        setMessage({ text: errorMessage, type: "error" });
      }

      config.formikOptions.setSubmitting(false);
    },
    onError: async () => {},
  });

  return (
    <>
      <Formik
        validateOnChange
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit: submitForValidation, isSubmitting, errors }) => (
          <Form className="flex flex-row w-full">
            <div className="w-full">
              <TextField
                type={"email"}
                placeholder={"Enter your email"}
                name={"email"}
                required
              />
              <FormMessage message={message} />
            </div>
            <div className="flex md:ml-2 flex-row justify-end items-center w-[120px] md:w-auto">
              <Button
                disabled={isSubmitting || errors?.length > 0}
                loading={isSubmitting}
                cta
                buttonText={isSubmitting ? "Submitting..." : _btnText.current}
                onClick={submitForValidation}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TextFieldAndButton;
