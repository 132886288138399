import React from "react";
import FooterTopElement from "components/elements/footer/footerTopElement";
import FooterCenterElement from "components/elements/footer/footerCenterElement";
import FooterBottomElement from "components/elements/footer/footerBottomElement";

const MainFooter = ({ global }) => {
  const footerData = global.attributes.siteFooter;
  // new news letter got separated from the siteFooter component on strapi
  const footerTop = global.attributes.footerTop;

  return (
    <footer className="px-4 bg-black">
      <div className="container w-full py-8 text-white sm:py-16">
        <FooterTopElement data={footerTop} />
        <hr className="py-2" />
        <FooterCenterElement data={footerData} />
        <hr className="py-3" />
        <FooterBottomElement data={footerData} />
      </div>
    </footer>
  );
};

export default MainFooter;
