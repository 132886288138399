import { ChevronDown } from "react-feather";
import Link from "next/link";

const NavLink = (props) => {
  const { dropdown, isActive, href, title, external, newTab } = props;

  // FIXME: move this section to reusable Anchor Component
  if (external) {
    return (
      <a
        className={`flex px-3 flex-row group-hover:border-primary border-b-2 border-transparent py-6 md:px-5 text-center md:text-xs lg:text-base md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out  ${
          isActive && "text-primary"
        }`}
        target={newTab ? "_blank" : "_self"}
        href={href}
        rel="noreferrer"
      >
        {title}
        {dropdown && <ChevronDown className="ml-1" size={18} />}
      </a>
    );
  }

  return (
    <Link href={`${href}`}>
      <a
        className={`flex px-3 flex-row group-hover:border-primary border-b-2 border-transparent py-6 md:px-5 text-center md:text-xs lg:text-base md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out  ${
          isActive && "text-primary"
        }`}
        target={newTab ? "_blank" : "_self"}
      >
        {title}
        {dropdown && <ChevronDown className="ml-1" size={18} />}
      </a>
    </Link>
  );
};

export const MobileNavLink = (props) => {
  const {
    dropdown,
    onClickDropdown = () => null,
    isDropdownOpen,
    isActive,
    href,
    title,
    external,
    newTab,
  } = props;

  if (dropdown) {
    return (
      <>
        {/* mobile dropdown toggle */}
        <button
          onClick={(e) => onClickDropdown(e, href)}
          className={`flex md:hidden justify-between px-3 flex-row w-full py-6 md:px-3 md:mx-1 text-left text-base md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out  ${
            isActive && "text-primary"
          }  ${
            isDropdownOpen
              ? "border-b-2 border-primary"
              : "border-b-2 border-transparent"
          }`}
        >
          {title}
          <ChevronDown className="ml-1" size={18} />
        </button>
      </>
    );
  }
  // FIXME: move this section to reusable Anchor Component
  if (external) {
    return (
      <a
        className={`flex px-3 flex-row py-6 md:px-5 text-center md:text-xs lg:text-base md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out  ${
          isActive && "text-primary"
        }`}
        target={newTab ? "_blank" : "_self"}
        href={href}
        rel="noreferrer"
      >
        {title}
      </a>
    );
  }

  return (
    <Link href={`${href}`}>
      <a
        className={`flex px-3 flex-row py-6 md:px-5 text-center md:text-xs lg:text-base md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out  ${
          isActive && "text-primary"
        }`}
        target={newTab ? "_blank" : "_self"}
      >
        {title}
      </a>
    </Link>
  );
};

export default NavLink;
