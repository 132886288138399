import React, { useState } from "react";
import classNames from "classnames";
import { ChevronDown } from "react-feather";
import tailwindConfig from "tailwind.config";

const LocationDropdown = (props) => {
  const { data, style, disabled } = props;

  const [selectedLocation, setSelectedLocation] = useState("Nigeria");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let dropDownListClassNames = classNames(
    "flex flex-col bg-black  transition duration-700 overflow-hidden rounded-b -mt-1",
    {
      "h-full": isOpen,
      "h-0": !isOpen,
    }
  );

  const _handleDropdownItemSelected = (datum) => {
    if (!loading) setLoading(true);
    setIsOpen(false);
    setSelectedLocation(datum);
  };

  return (
    <>
      <div
        onClick={() => {
          if (!disabled) setIsOpen((openState) => !openState);
        }}
        style={style}
        className="flex select-none cursor-pointer flex-col justify-around pt-1 pb-2 px-3 bg-black border border-white rounded min-w-40 h-11"
      >
        <div className="font-normal text-thrive-sonic-silver leading-[10.6px] m-0 p-0 text-micro">
          location
        </div>
        <div className="flex text-xs p-0 m-0 leading-[22.4px] flex-row items-center justify-between">
          {selectedLocation}{" "}
          <ChevronDown
            size={24}
            color={tailwindConfig.theme.extend.colors.white}
          />
        </div>
      </div>
      <div className={dropDownListClassNames}>
        {data.map((datum, datumIndex) => (
          <div
            onClick={() => _handleDropdownItemSelected(datum)}
            key={`location-item-${datumIndex}`}
            className="px-3 text-xs cursor-pointer select-none hover:bg-dark border py-2 last-of-type:rounded-b"
          >
            {datum}
          </div>
        ))}
      </div>
    </>
  );
};

export default LocationDropdown;
