import _ from "lodash";
import { useEffect, useState } from "react";

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

/** Handle verifying an interaction with a form through reCaptcha v3 API
 * provides handling for the reCaptcha v3 response and error events
 * @param {object} config - configuration object
 * @param {string} config.actionName - name of the action being verified
 * @param {number} config.minimumScore - minimum score required to pass
 * @param {function} config.onLoad - function to call when reCaptcha is loaded
 * @param {function} config.onSuccess - function to call when reCaptcha verification is successful
 * onSuccess will receive two params (values, options = {} - options will contain formikOptions and token )
 * @param {function} config.onError - function to call when reCaptcha is unsuccessful
 *
 */
export const useReCaptcha = (config = {}) => {
  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleSubmit = (values, formikOptions = {}) => {
    let _actionName = _.camelCase(config?.actionName) || "submit";
    // do nothing if no success handler is provided
    if (config?.onSuccess && _.isFunction(config?.onSuccess)) {
      setProcessing(true);
      window.grecaptcha.ready(() => {
        // get recaptcha token
        window.grecaptcha
          .execute(SITE_KEY, { action: _actionName })
          .then((token) => {
            config.onSuccess(values, {
              formikOptions,
              token,
              minimumScore: config?.minimumScore || 0.5,
              actionName: _actionName,
            });
            setProcessing(false);
            setCompleted(true);
          })
          .catch((action_error) => {
            setProcessing(false);
            if (config?.onError && _.isFunction(config?.onError))
              config.onError(action_error, { formikOptions });
          });
      });
    }
  };

  useEffect(() => {
    if (typeof grecaptcha === "undefined") {
      window.grecaptcha = {};
    }
    window.grecaptcha.ready = function (cb) {
      if (typeof grecaptcha === "undefined") {
        // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
        // configuration. By default, any functions listed in its 'fns' property
        // are automatically executed when reCAPTCHA loads.
        const c = "___grecaptcha_cfg";
        window[c] = window[c] || {};
        (window[c]["fns"] = window[c]["fns"] || []).push(cb);
      } else {
        cb();
      }
    };
  }, []);

  return {
    processing,
    completed,
    handleSubmit,
  };
};

export default useReCaptcha;
