import _ from "lodash";
import { useEffect, useState } from "react";

const useMessage = (config = { delay: 5000, permanent: false }) => {
  const [message, setMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    if (config.permanent) return;

    if (message.text === "") return;

    _.delay(() => {
      setMessage({ text: "", type: "" });
    }, config.delay);
  }, [config.delay, config.permanent, message]);

  return { message, setMessage };
};

export default useMessage;
