import React from "react";
import { oneSpaceApart } from "utils/utils";

const FormMessage = ({ message }) => {
  return (
    <div
      className={oneSpaceApart([
        `text-${message?.type === "success" ? "success" : "error"}`,
        "text-xs",
      ])}
    >
      <p>{message?.text || ""}</p>
    </div>
  );
};

export default React.memo(FormMessage);
