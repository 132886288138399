import React from "react";
import { quote } from "assets/jpg";
import Image from "next/image";

const RichTextQuote = ({ data }) => {
  // TODO: add alignment functionality
  const { text } = data;
  return (
    <div className="flex w-full">
      <Image
        src={quote}
        width={60}
        height={60}
        layout="fixed"
        alt='"'
        className="self-start"
      />
      <div className="flex flex-col">
        <p className="text-lg font-bold text-left">{text}</p>
        {/* <p className="text-lg font-bold leading-8 text-thrive-dark">
          {caption}
        </p> */}
      </div>
    </div>
  );
};

export default RichTextQuote;
