export const topNavigation = [
  {
    path: "/about-us",
    title: "About Us",
    children: [
      {
        type: "tab",
        path: "/about-us#vision",
        title: "Vision and Mission",
      },
      {
        type: "tab",
        path: "/about-us#values",
        title: "Values",
      },
      {
        type: "tab",
        path: "/about-us#theoryOfChange",
        title: "Theory of Change",
      },
      {
        type: "tab",
        path: "/about-us#management",
        title: "Management",
      },
      {
        type: "tab",
        path: "/about-us#advisors",
        title: "Advisors",
      },
      {
        external: true,
        newTab: true,
        path: "https://docs.google.com/forms/d/1gCMj6pFgn0sM4S79kQwTIuiAlC8YjoyGipfk6GA_gfU/edit?ts=624c4071",
        title: "Join our Farmily",
        // children: [
        // {
        //   path: "/about-us/employees",
        //   title: "Employees",
        // },
        // {
        //   path: "/about-us/partners",
        //   title: "Partners",
        // },
        // {
        //   path: "/about-us/communities",
        // title: "Communities",
        // },
        // ],
      },
    ],
  },
  {
    path: "/our-work",
    title: "Our Work",
    children: [
      {
        type: "tab",
        path: "/our-work#whatWeDo",
        title: "What We Do",
      },
      {
        type: "tab",
        path: "/our-work#ourTechnology",
        title: "Our Technology",
      },
      {
        type: "tab",
        path: "/our-work#ourProcess",
        title: "Our Process",
      },
    ],
  },
  {
    path: "/our-impact",
    title: "Our Impact",
    children: [
      {
        type: "tab",
        path: "/our-impact#farmerStories",
        title: "Farmer Stories",
      },
      {
        type: "tab",
        path: "/our-impact#partnerStories",
        title: "Partner Stories",
      },
      {
        type: "tab",
        path: "/our-impact#impactMap",
        title: "Impact Map",
      },
    ],
  },
  // {
  //   path: "/resources",
  //   title: "Resources",
  //   children: [
  //     {
  //       path: "/resources#hectare",
  //       title: "Hectare",
  //     },
  //     {
  //       path: "/resources#reports",
  //       title: "Reports",
  //     },
  //     {
  //       path: "/resources#gallery",
  //       title: "Gallery",
  //     },
  //     {
  //       path: "/resources#Press-And-Media",
  //       title: "Press And Media",
  //     },
  //   ],
  // },
  {
    path: "/contact-us",
    title: "Contact Us",
    // children: [
    //   {
    //     path: "/contact-us#HQAddress",
    //     title: "HQ Address",
    //   },
    //   {
    //     path: "/contact-us#email",
    //     title: "Email",
    //   },
    //   {
    //     path: "/contact-us#phoneNumber",
    //     title: "Phone Number",
    //   },
    //   {
    //     path: "/contact-us#socialMedia",
    //     title: "Social Media",
    //     children: [
    //       {
    //         path: "/about-us/employees",
    //         title: "Employees",
    //       },
    //       {
    //         path: "/about-us/partners",
    //         title: "Partners",
    //       },
    //     ],
    //   },
    // ],
  },
];
