/* eslint-disable @next/next/no-img-element */
import Button from "components/primary/button";
import Container from "components/sections/container";
import RichText from "components/sections/richText/richText";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import tailwindConfig from "tailwind.config";
import { oneSpaceApart, randomListItem } from "utils/utils";
import style from "../../styles/announcement.module.css";

export const ANNOUNCEMENT_HEIGHT = 64;

const Announcement = (props) => {
  const { data } = props;

  const [announcement, setAnnouncement] = useState();

  useEffect(() => {
    setAnnouncement(randomListItem(data, 0, data.length - 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (data?.length > 0)
    return (
      <Container
        style={{
          backgroundColor: tailwindConfig.theme.extend.colors.dark,
        }}
      >
        <div className="flex flex-row items-center min-h-16">
          <div
            className={oneSpaceApart([
              "flex flex-row items-center",
              style.announcementBar,
            ])}
          >
            <div
              className={
                "leading-7 text-white font-demibold text-tiny lg:text-h5 flex"
              }
            >
              {announcement?.message && (
                <RichText data={{ content: announcement?.message }} />
              )}
            </div>
          </div>
          {announcement?.cta && (
            <Link href={announcement.cta.url ?? "#0"}>
              <a className="ml-7 md:ml-14 text-lg my-2">
                <Button
                  className="whitespace-nowrap"
                  cta
                  buttonText={announcement.cta.text}
                />
              </a>
            </Link>
          )}
        </div>
      </Container>
    );

  return <></>;
};

export default Announcement;
