import { useState } from "react";
import { hyphenate } from "utils/utils";
import SubNavLink from "./subNavLink";
import SubNavMenu from "./subNavMenu";

const SubNav = (props) => {
  const { data, external } = props;
  const [nestedSubNavToggled, setNestedSubNavToggled] = useState();

  const handleNestedNavClicked = (_e, path) =>
    setNestedSubNavToggled((oldpath) => {
      if (oldpath === path) return null;
      return path;
    });

  return (
    <>
      {/* non-mobile subnav */}
      <nav
        className={`hidden py-4 px-5 group-hover:md:flex group-hover:opacity-100 transition-opacity opacity-0 ease-in-out duration-700 absolute w-[204px] -left-2 right-0 rounded-b bg-white flex-col`}
      >
        {/* list out submenu */}
        {data.map((subNavItem, subNavItemIndex) => {
          let pageData = subNavItem.attributes;
          return (
            <div
              className="relative block "
              key={hyphenate([
                "topNav",
                "subNavLink",
                "subNavMenu",
                subNavItemIndex,
              ])}
            >
              <SubNavLink
                subNavItem={subNavItem}
                href={pageData.full_slug}
                title={pageData.title}
                hasSubMenu={subNavItem?.children?.length > 0}
                onClickSubMenu={handleNestedNavClicked}
              />

              {/* // nested subnav menu */}
              {subNavItem?.children?.length > 0 && (
                <SubNavMenu
                  data={subNavItem.children || []}
                  isOpen={nestedSubNavToggled === pageData.full_slug}
                />
              )}
            </div>
          );
        })}
        {external?.outerUrl && (
          <div className="relative block">
            <SubNavLink
              href={external?.outerUrl}
              title={external?.externalTitle}
              hasSubMenu={false}
              onClickSubMenu={() => null}
              isExternal={true}
            />
          </div>
        )}
      </nav>
    </>
  );
};

export const MobileSubNav = (props) => {
  const { data, isOpen, external } = props;
  const [nestedSubNavToggled, setNestedSubNavToggled] = useState();
  const [mobileNestedSubNavToggled, setMobileNestedSubNavToggled] = useState();

  const handleNestedNavClicked = (_e, path) =>
    setNestedSubNavToggled((oldpath) => {
      if (oldpath === path) return null;
      return path;
    });
  const handleMobileNestedNavClicked = (_e, path) =>
    setMobileNestedSubNavToggled((oldpath) => {
      if (oldpath === path) return null;
      return path;
    });

  return (
    <>
      {/* non-mobile subnav */}
      <nav
        className={`hidden py-4 px-5 grou-hover:md:flex absolute w-[204px] -left-2 right-0 rounded-b bg-white flex-col ${
          isOpen ? "md:flex" : "hidden"
        }`}
      >
        {/* list out submenu */}
        {data.map((subNavItem, subNavItemIndex) => {
          let pageData = subNavItem.attributes;
          return (
            <div
              className="relative block"
              key={hyphenate([
                "topNav",
                "subNavLink",
                "subNavMenu",
                subNavItemIndex,
              ])}
            >
              <SubNavLink
                subNavItem={subNavItem}
                href={pageData.full_slug}
                title={pageData.title}
                hasSubMenu={subNavItem?.children?.length > 0}
                onClickSubMenu={handleNestedNavClicked}
              />

              {/* // nested subnav menu */}
              {subNavItem?.children?.length > 0 && (
                <SubNavMenu
                  data={subNavItem.children || []}
                  isOpen={nestedSubNavToggled === pageData.full_slug}
                />
              )}
            </div>
          );
        })}
        {external?.outerUrl && (
          <div className="relative block">
            <SubNavLink
              href={external?.outerUrl}
              title={external?.externalTitle}
              hasSubMenu={false}
              onClickSubMenu={() => null}
              isExternal={true}
            />
          </div>
        )}
      </nav>
      {/* mobile subnav */}
      <nav
        className={`md:hidden py-4 px-5 w-full rounded-b bg-white  ${
          isOpen ? "flex flex-col" : "hidden"
        }`}
      >
        {/* list out submenu */}
        {data.map((subNavItem, subNavItemIndex) => {
          let pageData = subNavItem.attributes;
          return (
            <div
              className="relative block"
              key={hyphenate([
                "topNav",
                "subNavLink",
                "subNavMenu",
                "mobile",
                subNavItemIndex,
              ])}
            >
              <SubNavLink
                subNavItem={subNavItem}
                href={pageData.full_slug}
                title={pageData.title}
                hasSubMenu={subNavItem?.children?.length > 0}
                onClickSubMenu={handleMobileNestedNavClicked}
              />
              {/* // nested subnav menu */}
              {subNavItem?.children?.length > 0 && (
                <SubNavMenu
                  data={subNavItem?.children || []}
                  isOpen={mobileNestedSubNavToggled === pageData.full_slug}
                />
              )}
            </div>
          );
        })}
        {external?.outerUrl && (
          <div className="relative block">
            <SubNavLink
              href={external?.outerUrl}
              title={external?.externalTitle}
              hasSubMenu={false}
              onClickSubMenu={() => {}}
              isExternal={true}
            />
          </div>
        )}
      </nav>
    </>
  );
};

export default SubNav;
