const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "16px",
        sm: "16px", //maintain 16px for non-mobile
        lg: "64px",
        // xl: "5rem",
        // "2xl": "6rem",
      },
    },
    // replace default spacing based on style guide(SG)
    spacings: {
      px: "1px",
      0: "0px",
      0.5: "0.25rem",
      1: "0.5rem",
      1.5: "0.75rem",
      2: "1.5rem",
      2.5: "1.625rem",
      3: "1.75rem",
      3.5: "1.875rem",
      4: "2rem",
      5: "2.25rem",
      6: "2.5rem",
      7: "2.75rem",
      8: "3rem",
      9: "3.25rem",
      10: "3.5rem",
      11: "3.75rem",
      12: "4rem",
      14: "4.5rem",
      16: "5rem",
      20: "6rem",
      24: "7rem",
      28: "8rem",
      32: "9rem",
      36: "10rem",
      40: "11rem",
      44: "12rem",
      48: "13rem",
      52: "14rem",
      56: "15rem",
      60: "16rem",
      64: "18rem",
      72: "20rem",
      80: "24rem",
    },
    extend: {
      // font-family classes only one font
      fontFamily: {
        sans: ["'TT Hoves'", ...defaultTheme.fontFamily.sans],
        poppins: "Poppings, sans-serif",
      },
      fontSize: {
        // overriding default body text sizes with (SG) values
        micro: "9px",
        tiny: "12px",
        xs: "14px",
        sm: "16px",
        base: "18px",
        lg: "20px",
        // adding new styles for heading based on SG
        h1: "56px",
        h2: "48px",
        h3: "36px",
        h4: "24px",
        h5: "14px",
        // other styles
        huge: "200px",
      },
      fontWeight: {
        demibold: 500,
        semibold: 600,
      },
      letterSpacing: {
        // only 3 letter spacing values from SG
        tight: "-0.02em" /* -2% */,
        2: "0.02em", //use this with '-' prefix to get "tight" vlaue
        normal: "0em",
        0: "0em", // same as normal /* 0% */
        wide: "0.15em",
        15: "0.15em", //same as wide /* 15% */
      },
      lineHeight: {
        tight: "1.2",
        normal: "1.6",
        120: "1.2", // usually used for heading text - same as 'tight'
        160: "1.6", // usually used for body text - same as 'normal'
        huge: "320px",
      },
      colors: {
        primary: "#48A928",
        secondary: "#000000",
        tertiary: "#FA746B",
        success: "#87D373", //same as thrive-mantis-green
        warning: "#F75E10", // same as thrive-orange
        error: "#9C0E02", // same as thrive-red
        black: "#000000", // same  as secondary, and thrive-black
        gray: "#B8B8B8", // same as thrive-grey and grey
        grey: "#B8B8B8", // same as thrive-grey and gray
        white: "#FFFFFF", // same as thrive-white
        dark: "#202020", //same as thrive-dark
        "pale-green": "#F5FBF3",
        thrive: {
          // thrive green - usage: illustrations, icons, UI, callouts
          "forest-green": "#013F11",
          "parkistan-green": "#20690C",
          "india-green": "#298810",
          "lime-green": "#5FC544",
          "apple-green": "#AFE2A1",
          "nyanza-green": "#D7F0D0",
          // thrive salmon - usage: SPARINGLY for highlighting things on CTAs
          "spanish-pink": "#F9C0C0",
          "unbleached-silk": "#FCD1C0",
          "misty-rose": "#F9E6E1",
          // neutral colors
          black: "#000000", // usage: for header text
          onyx: "#3D3D3D", // usage: for body text
          dark: "#202020",
          "sonic-silver": "#7A7A7A",
          grey: "#B8B8B8",
          platinum: "#EBEBEB",
          white: "#FFFFFF", // usage: for backgrounds or text on green
          // state colours
          "mantis-green": "#87D373",
          orange: "#F75E10",
          red: "#9C0E02",
          "pale-green": "#F5FBF3",
        },
      },
      height: {
        //  extend to work with viewport-height scale
        "5vh": "5vh",
        "10vh": "10vh",
        "15vh": "15vh",
        "20vh": "20vh",
        "25vh": "25vh",
        "30vh": "30vh",
        "35vh": "35vh",
        "40vh": "40vh",
        "45vh": "45vh",
        "50vh": "50vh",
        "55vh": "55vh",
        "60vh": "60vh",
        "65vh": "65vh",
        "70vh": "70vh",
        "75vh": "75vh",
        "80vh": "80vh",
        "85vh": "85vh",
        "90vh": "90vh",
        "95vh": "95vh",
        "main-header": "80px",
      },
      width: {
        //  extend to work with viewport-width scale
        "5vw": "5vw",
        "10vw": "10vw",
        "15vw": "15vw",
        "20vw": "20vw",
        "25vw": "25vw",
        "30vw": "30vw",
        "35vw": "35vw",
        "40vw": "40vh",
        "45vw": "45vw",
        "50vw": "50vw",
        "55vw": "55vw",
        "60vw": "60vw",
        "65vw": "65vw",
        "70vw": "70vw",
        "75vw": "75vw",
        "80vw": "80vw",
        "85vw": "85vw",
        "90vw": "90vw",
        "95vw": "95vw",
      },
      keyframes: {
        "scroll-horizontal": {
          "0%": { transform: "translateX(100%)" },
          "50%": { transform: "translateX(50%)" },
          "100%": { transform: "translateX(-100%)" },
        },
        "background-zoom": {
          "0%": {
            transform: "scale(1)",
          },
          "100%": {
            transform: "scale(1.2)",
          },
        },
        "smooth-scoll": {
          "0%": {
            transform: `translateY(-80px)`,
          },
          "100%": {
            transform: `translateY(0px)`,
          },
        },
        "bounce-vertical": {
          " 0%, 100%": {
            transform: "translateX(-25%)",
            "animation-timing-function": "cubic-bezier(0.8,0,1,1)",
          },
          "50%": {
            transform: "none",
            "animation-timing-function": "cubic-bezier(0,0,0.2,1)",
          },
        },
        spinner: {
          "0%": {
            "stroke-dashoffset": 0.66 * 20,
            transform: "rotate(0deg)",
          },
          "50%": {
            "stroke-dashoffset": 3.14 * 20,
            transform: "rotate(720deg)",
          },
          "100%": {
            "stroke-dashoffset": 0.66 * 20,
            transform: "rotate(1080deg)",
          },
        },
      },
      animation: {
        "scroll-horizontal": "scroll-horizontal 20s linear infinite",
        "background-zoom": "background-zoom 10s ease-in infinite",
        "smooth-scroll": "smooth-scroll 1s forwards",
        "bounce-vertical": "bounce-vertical 1s infinite",
        spinner: "spinner 4s infinite",
      },
    },
    plugins: [
      require("tailwindcss-debug-screens"),
      require("tailwind-scrollbar-hide"),
      require("@tailwindcss/typography"),
      require("@tailwindcss/forms"),
    ],
  },
};
