import Button from "components/primary/button";
import { Menu, X } from "react-feather";

const NavToggleButton = (props) => {
  const { onToggleNav, isNavOpen } = props;

  return (
    <Button onClick={onToggleNav} transparent className="text-dark md:hidden">
      <X size={32} className={`${isNavOpen ? "" : "hidden"}`} />
      <Menu size={32} className={`${isNavOpen ? "hidden" : ""}`} />
    </Button>
  );
};

export default NavToggleButton;
