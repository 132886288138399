import Link from "next/link";
import { hyphenate } from "utils/utils";

const SubNavMenu = (props) => {
  const { data, isOpen } = props;
  // TODO: integrate with strapi data structure
  return (
    <>
      {/* non-mobile subnav menu */}
      <nav
        className={`hidden py-4 px-5 absolute w-[204px] left-[118%] bottom-0 rounded bg-white flex-col ${
          isOpen ? "md:flex" : "hidden"
        }`}
      >
        {data.map((subNavMenuLink, subNavMenuLinkIndex) => (
          <Link
            href={subNavMenuLink.path}
            key={hyphenate([
              subNavMenuLink.path,
              "-subNavMenuLink-",
              subNavMenuLinkIndex,
            ])}
          >
            <a className="leading-5 text-sm font-normal p-[10px]">
              {subNavMenuLink.title}
            </a>
          </Link>
        ))}
      </nav>
      {/* mobile subNavMenu */}
      <nav
        className={` md:hidden border-t-2 border-t-primary py-4 px-5 w-full bg-white  ${
          isOpen ? "flex flex-col" : "hidden"
        }`}
      >
        {data.map((subNavMenuLink, subNavMenuLinkIndex) => (
          <Link
            href={subNavMenuLink.path}
            key={hyphenate([
              subNavMenuLink.path,
              "-subNavMenuLink-",
              subNavMenuLinkIndex,
            ])}
          >
            <a className="leading-5 text-sm font-normal p-[10px]">
              {subNavMenuLink.title}
            </a>
          </Link>
        ))}
      </nav>
    </>
  );
};

export default SubNavMenu;
