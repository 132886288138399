import { useCallback, useEffect, useState } from "react";
import NavLink, { MobileNavLink } from "components/navigation/navLink";
import SubNav, { MobileSubNav } from "components/navigation/subNav";
import OutsideClick from "components/outsideClick";
import { useRouter } from "next/router";
import { hyphenate } from "utils/utils";
import NavToggleButton from "./navToggleButton";

const TopNavigation = (props) => {
  const { data, setSticky } = props;
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [subNavToggled, setSubNavToggled] = useState(null);
  const [mobileSubNavToggled, setMobileSubNavToggled] = useState(null);

  const router = useRouter();

  const handleToggleNav = () => {
    setIsMobileNavOpen((isOpen) => {
      if (!isOpen) {
        setSticky({ isSticky: true });
      }
      return !isOpen;
    });
  };

  const handleSubNavClicked = (e, path) => {
    setSubNavToggled((oldpath) => {
      if (oldpath === path) return null;
      return path;
    });
  };
  const handleMobileSubNavClicked = (e, path) => {
    setMobileSubNavToggled((oldpath) => {
      if (oldpath === path) return null;
      return path;
    });
  };

  const handleResetSubNav = useCallback(() => {
    setSubNavToggled(null);
    setMobileSubNavToggled(null);
    setIsMobileNavOpen(false);
  }, []);

  const handleTouchOutside = () => {
    setSubNavToggled(null);
    setMobileSubNavToggled(null);
  };

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      handleResetSubNav();
    };

    router.events.on("routeChangeStart", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeComplete);
    };
  }, [handleResetSubNav, router.events]);

  return (
    <div className="top-nav-container">
      {/* non mobile nav*/}
      {/* <nav  className="top-nav"> */}
      {/* TODO: generate all the pages/ dynamically for these */}
      <OutsideClick
        component="nav"
        onClickOutside={handleTouchOutside}
        className="top-nav"
      >
        {data?.navLinks?.length > 0 &&
          data.navLinks?.map((navItem, navItemIndex) => {
            let { title, children, external, link, newTab } = navItem;
            let pageData = navItem?.page?.data?.attributes;
            return (
              <div
                key={hyphenate(["topNavItem", navItemIndex])}
                className="relative whitespace-nowrap group"
              >
                <NavLink
                  isActive={router.asPath === pageData?.full_slug}
                  dropdown={children?.data?.length > 0}
                  onClickDropdown={handleSubNavClicked}
                  isDropdownOpen={pageData?.full_slug === subNavToggled}
                  title={title}
                  href={external ? link : pageData?.full_slug}
                  external={external}
                  newtab={newTab}
                />
                {children.data?.length > 0 && (
                  // subnav menu
                  <SubNav
                    data={navItem.children.data}
                    isOpen={subNavToggled === pageData?.full_slug}
                    external={{
                      outerUrl: navItem?.externalUrlLink,
                      externalTitle: navItem?.externalUrlTitle,
                    }}
                  />
                )}
              </div>
            );
          })}
      </OutsideClick>
      {/* </nav> */}
      {/* mobile nav */}
      <nav
        className={`${
          isMobileNavOpen
            ? "mobile-top-nav--isOpen"
            : "mobile-top-nav--isClosed"
        } mobile-top-nav`}
      >
        {/* TODO: generate all the pages/ dynamically for these */}
        {data?.navLinks?.length > 0 &&
          data.navLinks?.map((navItem, navItemIndex) => {
            let { title, children, external, link, newTab } = navItem;
            let pageData = navItem?.page?.data?.attributes;
            return (
              <div
                key={hyphenate(["topNavItem", navItemIndex])}
                className="relative"
              >
                <MobileNavLink
                  isActive={router.asPath === pageData?.full_slug}
                  dropdown={children?.data?.length > 0}
                  onClickDropdown={handleMobileSubNavClicked}
                  isDropdownOpen={mobileSubNavToggled === pageData?.full_slug}
                  title={title}
                  href={external ? link : pageData?.full_slug}
                  newTab={newTab}
                />
                {children?.data?.length > 0 && (
                  // subnav menu

                  <MobileSubNav
                    data={children.data}
                    isOpen={mobileSubNavToggled === pageData?.full_slug}
                    external={
                      navItem?.externalUrlLink && {
                        outerUrl: navItem?.externalUrlLink,
                        externalTitle: navItem?.externalUrlTitle,
                      }
                    }
                  />
                )}
              </div>
            );
          })}
      </nav>
      <NavToggleButton
        onToggleNav={handleToggleNav}
        isNavOpen={isMobileNavOpen}
      />
    </div>
  );
};

// NavLink.displayName = getComponentDisplayName()

export default TopNavigation;
