import React from "react";

const RichTextLink = ({ data }) => {
  console.log(data);
  return (
    <div className="flex flex-row justify-center">
      <a
        className="color-transition-300 hover-ease-in-out flex flex-row h-12  max-w-fit  w-full py-4 text-center items-center text-white rounded-lg px-4 sm:px-7"
        href=""
      ></a>
    </div>
  );
};

export default RichTextLink;
