import React from "react";

const RichTextCode = ({ data }) => {
  return (
    <pre className="w-full overflow-auto">
      <code>{data?.code}</code>
    </pre>
  );
};

export default RichTextCode;
