import React, { useEffect, useRef } from "react";

const OutsideClick = (props) => {
  const {
    children,
    onClickOutside,
    render,
    style,
    className,
    onFocusOutside,
    component,
  } = props;

  let clickCaptured = useRef(false);
  let focusCaptured = useRef(false);

  const init = () => {
    document.addEventListener("mousedown", documentClick);
    document.addEventListener("focus", documentFocus, true);
    document.addEventListener("touchstart", documentClick);
  };
  const flush = () => {
    document.removeEventListener("mousedown", documentClick);
    document.removeEventListener("focus", documentFocus, true);
    document.removeEventListener("touchstart", documentClick);
  };

  const innerClick = () => {
    clickCaptured.current = true;
  };

  const innerFocus = () => {
    focusCaptured.current = true;
  };

  const getProps = () => {
    return {
      className,
      style,
      onMouseDown: innerClick,
      onFocus: innerFocus,
      onTouchStart: innerClick,
    };
  };

  const documentClick = (event) => {
    if (clickCaptured.current !== true && onClickOutside) {
      onClickOutside(event);
    }
    clickCaptured.current = false;
  };

  const documentFocus = (event) => {
    if (focusCaptured !== true && onFocusOutside) {
      onFocusOutside(event);
    }
    focusCaptured.current = false;
  };

  const renderComponent = () => {
    return React.createElement(component || "span", getProps(), children);
  };

  useEffect(() => {
    init();
    return flush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderFunction = () => {
    const _render = render || children;
    if (typeof render === "function") {
      return _render(getProps());
    }

    return renderComponent();
  };

  return _renderFunction();
};
export default OutsideClick;
