import Link from "next/link";
import { ChevronDown, ChevronRight } from "react-feather";

const SubNavLink = (props) => {
  const { href, title, hasSubMenu, onClickSubMenu, isExternal } = props;

  if (hasSubMenu) {
    return (
      <button
        onClick={(e) => onClickSubMenu(e, href)}
        className={`flex flex-row items-center py-6 px-3 text-left text-sm md:py-4 leading-5 font-normal hover:text-thrive-india-green transition-colors duration-300 hover:ease-in-out`}
      >
        {title}
        <ChevronRight size={18} className="hidden ml-1 md:inline" />
        <ChevronDown size={18} className="inline ml-1 md:hidden" />
      </button>
    );
  }

  return isExternal ? (
    <a
      className="leading-5 block text-sm font-normal p-[10px]"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {title}
    </a>
  ) : (
    <Link href={href}>
      <a className="leading-5 block text-sm font-normal p-[10px]">{title}</a>
    </Link>
  );
};

export default SubNavLink;
