import React from "react";
import sanitizeHtml from "sanitize-html-react";
import he from "he";

const RichTextParagraph = (props) => {
  const { data } = props;
  if (!data?.text) return <></>;

  let text = he.decode(data.text, { isAttributeValue: true });

  let sanitizedContent = sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      "h1",
      "h2",
      "mark",
      "u",
      "s",
      "img",
    ]),
  });

  return (
    <div
      className="my-2"
      dangerouslySetInnerHTML={{
        __html: `<p class="paragrapgh"> ${sanitizedContent} </p>`,
      }}
    />
  );
};

export default RichTextParagraph;
