import React from "react";
import EitherPageLink from "./footerLink";

const FooterLinkColumn = ({ data }) => {
  return (
    <div className="flex flex-col gap-4 max-w-[150px]">
      <p className="font-demibold">{data.title}</p>
      {data?.link &&
        data?.link?.map((data, index) => {
          return (
            <div className="text-xs font-normal cursor-pointer" key={index}>
              <EitherPageLink data={data} />
            </div>
          );
        })}
    </div>
  );
};

export default FooterLinkColumn;
