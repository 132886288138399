import qs from "qs";

export const resolveStrapiBaseUrl = () => {
  const PRODUCTION_BASE_URL = process.env.NEXT_PUBLIC_STRAPI_PRODUCTION_URL;
  const STAGING_BASE_URL = process.env.NEXT_PUBLIC_STRAPI_STAGING_URL;
  const LOCAL_BASE_URL = process.env.NEXT_PUBLIC_STRAPI_LOCAL_URL;

  // otherwise resolve via NEXT_PUBLIC_STRAPI_ENVIRONMENT
  switch (process.env.NEXT_PUBLIC_STRAPI_ENVIRONMENT) {
    case "development":
      return LOCAL_BASE_URL;
    case "staging":
      return STAGING_BASE_URL;
    case "production":
      return PRODUCTION_BASE_URL;
    default:
      return "http://localhost:1337";
  }
};

export function getStrapiURL(path) {
  let baseUrl = resolveStrapiBaseUrl();
  return `${baseUrl}${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {RequestInit} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path, urlParamsObject = {}, options = {}) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject, { encodeValuesOnly: true });
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ""}`
  )}`;

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    console.error(response.statusText);
    throw new Error(`An error occured please try again`);
  }
  const data = await response.json();
  // printObj(data, `data from fetchApi ${path}`);
  // console.log("data from fatchAPI", data);
  return data;
}

/**
 * Helper to make POST requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} body request body for post
 * @param {RequestInit} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function postAPI(path, body, options = {}) {
  try {
    // Merge default and user options
    const mergedOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    };

    // Build request URL
    const requestUrl = `${getStrapiURL(`/api${path}`)}`;

    // Trigger API call and return response data
    return await fetch(requestUrl, mergedOptions);
  } catch (err) {
    console.error(err);
    return;
  }
}

/** Fetch a Page based on slug and return all it data
 *
 * @param {Object} options
 * @param {string} options.slug The page's slug
 * @param {string} options.locale The current locale specified in router.locale
 * @param {boolean} options.preview router isPreview value
 */
export async function getPageData({ full_slug, locale, preview }) {
  // Find the pages that match this slug
  const pagesData = await fetchAPI("/pages", {
    filters: {
      full_slug: {
        $eq: full_slug ?? null,
      },
    },
    populate: "deep",

    fields: "*",
    locale,
    preview,
  });
  // const pagesData = await pagesRes.json();
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null;
  }
  // printObj(pagesData.data, "pagesData.data from getPageData");
  // Return the first item since there should only be one result per slug
  return pagesData.data[0];
}

// Get site data from Strapi (metadata, navbar, footer...)
export async function getGlobalData(locale) {
  const globalEndpoint = getStrapiURL("/api/global");
  const query = qs.stringify(
    {
      locale: locale,
      fields: "*",
      populate: {
        metadata: "*",
        favicon: "*",
        announcements: { populate: "*" },
        navbar: {
          populate: ["navLinks", "navLinks.page", "navLinks.children"],
        },
        logo: "*",
        siteFooter: {
          populate: {
            footerLogo: "*",
            footerBottomLinks: "*",
            footerTopCTA: "*",
            footerLinks: { populate: "*" },
          },
        },
        footerTop: { populate: "*" },
      },
    },
    { encodeValuesOnly: true }
  );
  const globalRes = await fetch(`${globalEndpoint}?${query}`);
  const global = await globalRes.json();

  return global.data;
}

///used by to get categories and keywords in resources pages
export const getFilterData = async (path) => {
  try {
    const res = await fetchAPI(path, {
      populate: "*",
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};
