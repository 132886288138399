import React, { createElement } from "react";
import { hyphenate } from "utils/utils";
import he from "he";
import classNames from "classnames";

const RichTextList = ({ data }) => {
  const { style, items } = data;

  if (!items?.length) return <></>;

  const listElement = style === "ordered" ? "ol" : "ul";
  const listClassNames = classNames("list-inside ", {
    "list-decimal": style === "ordered",
    "list-disc": style === "unordered",
  });
  const listItems = () =>
    items.map((item, index) => {
      return (
        <li
          dangerouslySetInnerHTML={{
            __html: `${he.decode(item, { isAttributeValue: true })}`,
          }}
          className="my-4 text-base"
          key={hyphenate([style, "list", index])}
        ></li>
      );
    });
  return createElement(listElement, { className: listClassNames }, listItems());
};

export default RichTextList;
