import React from "react";
import { hyphenate, tryJSONdecode } from "utils/utils";
import RichTextChecklist from "./richTextChecklist";
import RichTextCode from "./richTextCode";
import RichTextDelimeter from "./richTextDelimeter";
import RichTextEmbed from "./richTextEmbed";
import RichTextHeading from "./richTextHeading";
import RichTextHtml from "./richTextHtml";
import RichTextImage from "./richTextImage";
import RichTextLink from "./richTextLink";
import RichTextList from "./richTextList";
import RichTextParagraph from "./richTextParagraph";
import RichTextQuote from "./richTextQuote";
import RichTextTable from "./richTextTable";

// map editorjs blocks to components
const richTextBlocks = {
  paragraph: RichTextParagraph,
  embed: RichTextEmbed,
  table: RichTextTable,
  list: RichTextList,
  code: RichTextCode,
  linkTool: RichTextLink,
  image: RichTextImage,
  raw: RichTextHtml,
  header: RichTextHeading,
  quote: RichTextQuote,
  text: RichTextHtml, // [inline-code, marker, ...other text elements]
  checklist: RichTextChecklist,
  delimiter: RichTextDelimeter,
};

// display an individual richTextBlock
const RichTextBlock = ({ block }) => {
  let { type, data } = block;
  // select a richText component
  const RichTextComponent = richTextBlocks[type];

  // type does not exist in richText components
  if (!RichTextComponent) return null;

  return <RichTextComponent data={data} />;
};

/** Parse and render a JSON string of editorJS output */
const RichText = ({ data }) => {
  let { content } = data;

  // the JSON String
  if (!content?.length) return <></>;

  // parse blocks JSON string to editorJS output
  let _content = tryJSONdecode(content);

  return (
    <div className="max-w-none">
      {_content?.blocks?.length &&
        _content.blocks.map((block) => (
          <RichTextBlock
            key={hyphenate(["rich-text-block", block.id])}
            block={block}
          />
        ))}
    </div>
  );
};
export default RichText;
