import LocationDropdown from "components/locationDropdown";
import ThriveLogo from "components/primary/logo";
import React from "react";
import FooterLinkColumn from "./footerLinkColumn";

const FooterCenterElement = ({ data }) => {
  return (
    <div className="flex flex-col py-1 border-white md:justify-between md:flex-row lg:h-72">
      <div>
        <ThriveLogo dark />
        <div className="mt-11">
          <LocationDropdown data={data?.dropDown ?? ["Nigeria"]} />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4 md:mt-0 md:flex-row">
        {data?.footerLinks?.length &&
          data.footerLinks?.map((data, index) => {
            return <FooterLinkColumn data={data} key={index} />;
          })}
      </div>
    </div>
  );
};

export default FooterCenterElement;
