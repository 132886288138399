import Heading from "components/primary/heading";
import React from "react";

const RichTextHeading = ({ data }) => {
  let { level, text } = data;

  return <Heading level={level} text={text} />;
};

export default RichTextHeading;
