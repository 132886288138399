/* eslint-disable @next/next/no-img-element */
import React from "react";
import MainHeader from "components/header/mainHeader";
import MainFooter from "components/layouts/mainFooter";
import TabNavigation from "components/navigation/tabNavigation";
import Hero from "../sections/hero";

const MainLayout = ({ children, loading, global, pageContext, pageData }) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <MainHeader
        global={global}
        pageContext={pageContext}
        pageData={pageData}
      />
      {pageData?.pageHero && <Hero data={pageData.pageHero} />}
      {pageData?.tabs && pageData.showTabs && (
        <TabNavigation
          data={pageData.tabs}
          global={global}
          pageContext={pageContext}
        />
      )}
      {loading ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <img src="/images/gif/ThriveAgric Logo.gif" alt="logo" />
        </div>
      ) : (
        <main>{children}</main>
      )}
      <MainFooter global={global} pageContext={pageContext} />
    </div>
  );
};

export default MainLayout;
