import TextFieldAndButton from "components/form/textFieldAndButton";
import RichText from "components/sections/richText/richText";

const FooterTopElement = ({ data }) => {
  return (
    <div className="flex flex-col justify-between pb-8 md:gap-4 md:flex-row">
      <div className="w-full text-white md:w-1/2">
        {data?.callToActionText && (
          <RichText data={{ content: data?.callToActionText }} />
        )}
      </div>
      <div className="flex items-center w-full gap-4 md:w-1/2">
        <TextFieldAndButton data={data} />
      </div>
    </div>
  );
};

export default FooterTopElement;
