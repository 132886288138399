import { topNavigation } from "./navigationData";

export const globalData = {
  id: "1",
  attributes: {
    metadata: {
      metaTitle: "ThriveAgric",
      metaDescrition:
        "We use technology to support smallholder farmers to make their processes easier and more efficient. From when they put a seed in the ground to when they bring harvests to the warehouse.",
    },
    favicon: {
      data: {
        attributes: {},
        id: "1",
      },
    },
    metaTitleSuffix: "Enabling agriculture...first, for the farmer. Farmers",
    footer: {
      id: "1",
      top: {},
      columns: [],
      logo: {},
      copyrightText: "",
      bottomLinks: [],
    },
    navbar: {
      logo: {
        href: "/",
        width: 158,
        height: 51,
        light: true,
        altText: "Thrive Agric Official Logo",
      },
      links: topNavigation,
    },
    announcements: [
      {
        message:
          "ThriveAgric becomes 1st organisation to sign MOU with Nigeria Commodity Exchange (NCX)",
      },
      {
        message:
          "ThriveAgric secures $56.4m in debt funding raise for pan-Africa expansion plans",
      },
    ],
  },
};
