import React from "react";
import sanitizeHtml from "sanitize-html-react";

const RichTextHtml = ({ data }) => {
  if (!data?.html) return <></>;

  let sanitizedContent = sanitizeHtml(data.html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      "h1",
      "h2",
      "mark",
      "u",
      "s",
      "img",
    ]),
  });

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

export default RichTextHtml;
