import React from "react";
import Link from "next/link";
import Button from "components/primary/button";
const LinkButton = ({ data }) => {
  if (data.external) {
    return (
      <a href={data.url} target={(data.newTab && "_blank") || "_self"}>
        <Button cta type={data.type} buttonText={data?.text} />
      </a>
    );
  }

  return (
    <Link href={data?.page?.data?.attributes?.full_slug || ""}>
      <a>
        <Button cta type={data.type} buttonText={data?.text} />
      </a>
    </Link>
  );
};

export default LinkButton;
