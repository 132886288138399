/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import App from "next/app";
import Head from "next/head";
import ErrorPage from "next/error";
import { DefaultSeo } from "next-seo";
import { getGlobalData } from "utils/api";
import { getStrapiMedia } from "utils/media";
import DefaultLayout from "components/layouts/mainLayout";

import "react-multi-carousel/lib/styles.css";
import "styles/globals.css";
import { useRouter } from "next/router";
function MyApp({ Component, pageProps }) {
  // get global props from getStaticProps
  const { global } = pageProps;
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const routeChangeStart = () => {
      setLoading(true);
      console.log("loading start");
    };
    const routeChangeEnd = () => {
      setLoading(false);
      console.log("loading end");
    };
    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);

    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeEnd);
      router.events.off("routeChangeError", routeChangeEnd);
    };
  }, [router.events]);

  // if no global props return 404
  if (global == null) {
    return <ErrorPage statusCode={404} />;
  }

  const Layout = Component.Layout || DefaultLayout;

  // get SEO attributes from global props
  const { metadata, favicon, metaTitleSuffix } = global.attributes;
  const faviconUrl = favicon?.data?.attributes?.url
    ? favicon.data.attributes.url
    : "/favicon.ico";
  return (
    // <Provider store={store}>  // redux is not yet in use
    <>
      <Head>
        {faviconUrl && (
          <link rel="shortcut icon" href={getStrapiMedia(faviconUrl)} />
        )}

        {/* Global Site metadata */}
        <DefaultSeo
          titleTemplate={`%s - ${metaTitleSuffix}`}
          title={metadata.metaTitle}
          description={metadata.metaDescription}
          // TODO: complete setup for default Seo metas
          // openGraph={{
          // }}
          twitter={{
            cardType: metadata.twitterCardType,
            handle: metadata.twitterUsername,
          }}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <Layout loading={loading} global={global} pageData={pageProps.pageData}>
        <Component {...pageProps} />
      </Layout>
    </>
    // </Provider>
  );
}

// According to starter example.......
// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...full_slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext) => {
  // Calls page's `getinitialProps` and fills appProps.pageProps
  const appProps = await App.getInitialProps(appContext);
  const globalLocale = await getGlobalData(appContext.router.locale);

  return {
    ...appProps,
    pageProps: {
      global: globalLocale,
    },
  };
};

export default MyApp;
