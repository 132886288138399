import { DEFAULT_HERO_IMAGE } from "assets/webp";
import LinkButton from "components/elements/LinkButton";
import Container from "components/sections/container";
import React, { useEffect } from "react";
import scroll from "react-scroll";
import { oneSpaceApart } from "utils/utils";
/** (Optional) Page Hero Section
 *
 * @param {Object} props - props object contains hero data such as title, subtitle, images, and optional links
 * @returns
 */
const Hero = (props) => {
  const { data } = props;
  useEffect(() => {
    if (data.scrollPastHero) {
      scroll.animateScroll.scrollTo(screen.height / 1.2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    title,
    subtitle,
    callToActions = [],
    largeBackground,
    smallBackground,
  } = data;

  return (
    <HeroContainer
      imageURL={{ desktop: largeBackground, mobile: smallBackground }}
    >
      <div className="h-[100vw] md:h-screen lg:min-h-[787px]">
        {/* content container */}
        <div className="flex flex-col items-start justify-center h-full">
          <div className="max-w-2xl text-white min-w-xs lg:mt-[-80px]">
            <h1 className="font-bold leading-120 text-h4 sm:text-h3 lg:text-h2">
              {title}
            </h1>
            <p className="mt-6 text-xs sm:text-lg font-normal leading-1.6 pb-4 md:pb-10">
              {subtitle || " "}
            </p>
            {/* Button Links */}
            {callToActions?.length
              ? callToActions.map((cta, index) => {
                  return <LinkButton data={cta} key={index} />;
                })
              : undefined}
          </div>
        </div>
      </div>
    </HeroContainer>
  );
};

/** A container component to toggle between mobile and desktop hero background
 *  @author Kenechukwu Offokansi <kenechukwu.offokansi@gmail.com>
 *
 */
const HeroContainer = ({ imageURL: { desktop, mobile }, children }) => {
  const innerClassName = oneSpaceApart(["container", "h-full"]);
  return (
    <>
      <Container
        additionalClass="hidden md:flex"
        innerClassName={innerClassName}
        style={{
          backgroundImage: `url(${
            desktop?.data?.attributes
              ? desktop.data.attributes.url
              : DEFAULT_HERO_IMAGE
          })`,
          backgroundColor: "black",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {children}
      </Container>
      <Container
        additionalClass="flex md:hidden "
        innerClassName={innerClassName}
        style={{
          backgroundImage: `url(${
            mobile?.data?.attributes
              ? mobile.data.attributes.url
              : DEFAULT_HERO_IMAGE
          })`,
          backgroundColor: "black",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vw",
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default Hero;
