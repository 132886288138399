import React from "react";
import PropTypes from "prop-types";
import { oneSpaceApart } from "utils/utils";
/** section wrapper, serves as a container for consistent
 * sizing and spacing or sections
 *
 * */

let isDevMode = false;
if (process && process.env.NODE_ENV === "development") {
  isDevMode = true;
}
const Container = (props) => {
  const {
    children,
    className = `flex flex-col ${!isDevMode ? " overflow-hidden" : ""}`,
    innerClassName = "container",
    style, // NOTE: please use style prop to do any inline customisation for this component
    innerStyle,
    additionalClass,
    id,
  } = props;

  return (
    <section
      id={id}
      style={style}
      className={oneSpaceApart([className, additionalClass])}
    >
      <div style={innerStyle} className={innerClassName}>
        {children}
      </div>
    </section>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Container;
