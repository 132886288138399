import React from "react";

const RichTextDelimeter = () => {
  return (
    <div className="my-8 font-extrabold text-xl text-dark flex flex-row justify-center">
      {" "}
      * * *{" "}
    </div>
  );
};

export default RichTextDelimeter;
