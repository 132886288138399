import { ErrorMessage, Field } from "formik";
import FormMessage from "./formMessage";

const TextField = ({ label, type, disabled, placeholder, message, name }) => {
  return (
    <div className="w-full my-2 text-input_root md:my-0">
      {label && (
        <>
          <label htmlFor={name}>{label}</label>
        </>
      )}
      <div className="">
        <Field
          type={type}
          name={name}
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          className="w-full text-dark text-base border border-1 border-thrive-sonic-silver p-2.5 rounded-md"
        />
      </div>
      <ErrorMessage
        component={"p"}
        className="text-error text-xs"
        name={name}
      />
      <FormMessage message={message} />
    </div>
  );
};

export default TextField;
