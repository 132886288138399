import {
  FACEBOOK_ICON,
  INSTAGRAM_ICON,
  LINKEDIN_ICON,
  TWITTER_ICON,
  YOUTUBE_ICON,
} from "assets/svg";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const iconMap = {
  facebook: FACEBOOK_ICON,
  twitter: TWITTER_ICON,
  instagram: INSTAGRAM_ICON,
  youtube: YOUTUBE_ICON,
  linkedin: LINKEDIN_ICON,
};

export const InPageLinkTag = ({ data }) => {
  const { icon, href, label } = data;
  return (
    <div className="flex flex-row items-center gap-3">
      {icon && (
        <Image
          src={iconMap[icon] ?? iconMap["facebook"]}
          alt={"logo"}
          width={14}
          height={14}
          layout="fixed"
        />
      )}
      <Link href={href}>
        <a>{label}</a>
      </Link>
    </div>
  );
};
export const NewPageLink = ({ data }) => {
  const { icon, label, href } = data;

  return (
    <div className="flex flex-row items-center gap-3">
      {icon && (
        <Image
          src={iconMap[icon] ?? iconMap["facebook"]}
          alt={"logo"}
          width={14}
          height={14}
          layout="fixed"
        />
      )}
      <a href={href} target="_blank" rel="noreferrer">
        {label}
      </a>
    </div>
  );
};

export const EitherPageLink = ({ data }) => {
  const { url, text, icon, external } = data;
  return external ? (
    <NewPageLink data={{ label: text, href: url, icon }} />
  ) : (
    <InPageLinkTag data={{ label: text, href: url, icon }} />
  );
};
export default EitherPageLink;
