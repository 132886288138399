import classNames from "classnames";
import { HEADER_HEIGHT } from "components/header/mainHeader";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import styles from "styles/tab.module.css";
import { oneSpaceApart } from "utils/utils";

const TabNavigation = ({ data }) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  const router = useRouter();
  let tabRef = useRef(null);
  let tabClassNames = classNames(styles.tabs, {
    [`${styles.sticky}`]: sticky.isSticky,
    "scrollbar-hide": true,
  });
  const handleScroll = (topOffset, height) => {
    if (topOffset < HEADER_HEIGHT) {
      setSticky({ isSticky: true, offset: height });
    }
    if (window.scrollY < height + HEADER_HEIGHT) {
      setSticky({ isSticky: false, offset: 0 });
    }
  };
  useEffect(() => {
    const toggleTabIsSticky = () => {
      if (tabRef.current) {
        const tabs = tabRef.current.getBoundingClientRect();
        handleScroll(tabs.top, tabs.height);
      }
    };
    window.addEventListener("scroll", toggleTabIsSticky);
    return () => {
      window.removeEventListener("scroll", toggleTabIsSticky);
    };
  }, []);

  return (
    <div style={{ marginTop: sticky.offset }}>
      <div ref={tabRef} className={tabClassNames}>
        <div className="container flex flex-row">
          {data?.tabLinks?.length &&
            data.tabLinks.map((tabLink, tabLinkIndex) => (
              <TabNavLink
                key={`${tabLinkIndex}-tabnavlink-${tabLink.id}`}
                path={tabLink.page.data.attributes.full_slug}
                title={tabLink.title}
                isActive={
                  router.asPath === tabLink.page.data.attributes.full_slug
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const TabNavLink = ({ path, title, isActive }) => (
  <Link href={`${path}`}>
    <a
      className={oneSpaceApart([
        styles["tab-link"],
        `${isActive ? styles.active : ""} whitespace-nowrap`,
      ])}
    >
      {title}
    </a>
  </Link>
);

export default TabNavigation;
