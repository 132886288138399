/** return each string in an array as a hypenated string */
export const hyphenate = (strArray) => `${strArray.join("-")}`;

/** turn an array of strings to a single string seperated by single space */
export const oneSpaceApart = (strArray) => strArray.join(" ");

/** get the display name of a (react) component */
export const getComponentDisplayName = (
  component,
  config = { optionalName: "unNamed Object" }
) => {
  return component?.displayName || component?.name || config.optionalName;
};

/** return a random integer within range min and max inclusive of min and max */
export const randomInt = (min, max) => {
  let _min = Math.ceil(min);
  let _max = Math.floor(max);
  return Math.floor(Math.random() * (_max - _min + 1)) + _min;
};

/** get a random list Item from an array specifying an index range */
export const randomListItem = (dataArray, min, max) => {
  if (!Array.isArray(dataArray) || dataArray.length === 0) return;
  let randomIndex = randomInt(min, max);
  return dataArray[randomIndex];
};

/**
 * Pretty print js object - (useful in server side console log )
 * @param {Object} obj - JS object to prettify
 * */
export const printObj = (obj, title = "printing>>>") => {
  console.log(
    "===================\n[PRINT OBJ]: " +
      title +
      "\n===================\n" +
      JSON.stringify(obj, null, 2)
  );
};

/** remove the leading slash in a string
 * Usage: the next/router router Object has an asPath field
 *         which is same as full_slug except for the leading slash
 */
export const removeLeadingSlash = (_string) => {
  if (_string.length > 1 && _string.charAt(0) === "/") {
    return _string.slice(1);
  }
  return _string;
};

/** prefix a string with a forward slash*/
export const addLeadingSlash = (_string) => {
  if (_string.length > 1 && _string.charAt(0) !== "/") {
    return `/${_string}`;
  }

  return _string;
};

/**
 * filter is a ref object..
 * it returns the filters object for strapi api strictly for hectare blog
 */
export const filterHectareBlog = (filter) => {
  const filters = {};

  if (filter.current.Keyword) {
    filters.keywords = {
      slug: {
        $eq: filter.current.Keyword,
      },
    };
    if (filter.current.Keyword === "Keyword") {
      delete filters.keywords;
    }
  }
  if (filter.current.Category) {
    filters.category = {
      slug: {
        $eq: filter.current.Category,
      },
    };
    if (filter.current.Category === "Category") {
      delete filters.category;
    }
  }
  if (filter.current.Year) {
    filters.Blogheader = {
      date: {
        $gte: `${filter.current.Year}-01-01`,
        $lte: `${filter.current.Year}-12-31`,
      },
    };
    if (filter.current.Year === "Year") {
      delete filters.Blogheader;
    }
  }
  if (filter.current.Country) {
    filters.country = {
      $eq: filter.current.Country,
    };
    if (filter.current.Country === "Country") {
      delete filters.country;
    }
  }
  return filters;
};

export const filterReport = (filter) => {
  const filters = {};

  if (filter.current.Keyword) {
    filters.report_keywords = {
      slug: {
        $eq: filter.current.Keyword,
      },
    };
    if (filter.current.Keyword === "Keyword") {
      console.log("enterd keywords");
      delete filters.report_keywords;
    }
  }
  if (filter.current.Category) {
    filters.report_category = {
      slug: {
        $eq: filter.current.Category,
      },
    };
    if (filter.current.Category === "Category") {
      delete filters.report_category;
    }
  }
  if (filter.current.Year) {
    filters.createdAt = {
      $gte: `${filter.current.Year}-01-01`,
      $lte: `${filter.current.Year}-12-31`,
    };
    if (filter.current.Year === "Year") {
      delete filters.createdAt;
    }
  }
  return filters;
};
export const splitTitle = ({ title = "" }) => {
  const split = title.split("___");
  const mainTitle = split?.[0];
  const subLink = split?.[1];
  return { mainTitle, subLink };
};
export const tryJSONdecode = (json) => {
  // confirm json is a string
  if (!json || typeof json !== "string") return;
  // try to decode json
  try {
    return JSON.parse(json);
  } catch (_) {
    return;
  }
};

export const parseRichTextObjForText = (obj) => {
  return obj?.blocks
    ?.map((e) => e?.data?.text?.replace(/(<([^>]+)>)/gi, ""))
    ?.join(" ");
};

export const filterGallery = (filter) => {
  const filters = {};

  if (filter.current.Category) {
    filters.gallery_categories = {
      slug: {
        $eq: filter.current.Category,
      },
    };
    if (filter.current.Category === "Category") {
      delete filters.gallery_categories;
    }
  }
  if (filter.current.Year) {
    filters.createdAt = {
      $gte: `${filter.current.Year}-01-01`,
      $lte: `${filter.current.Year}-12-31`,
    };
    if (filter.current.Year === "Year") {
      delete filters.createdAt;
    }
  }

  return filters;
};

////////////press and media//////////////////////
export const filterPressMedia = (filter) => {
  const filters = {};

  if (filter.current.Category) {
    filters["press_media_category"] = {
      slug: {
        $eq: filter.current.Category,
      },
    };
    if (filter.current.Category === "Category") {
      delete filters["press_media_category"];
    }
  }
  if (filter.current.Year) {
    filters.createdAt = {
      $gte: `${filter.current.Year}-01-01`,
      $lte: `${filter.current.Year}-12-31`,
    };
    if (filter.current.Year === "Year") {
      delete filters.createdAt;
    }
  }
  // if (filter.current.country) {
  //   filters.country = {
  //     $eq: filter.current.country,
  //   };
  //   if (filter.current.country === "country") {
  //     delete filters.country;
  //   }
  // }

  return filters;
};
