import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Announcement, {
  ANNOUNCEMENT_HEIGHT,
} from "components/header/announcement";
import TopNavigation from "components/navigation/topNavigation";
import ThriveLogo from "components/primary/logo";
import { globalData } from "mockData/global";

export const HEADER_HEIGHT = 80;

const MainHeader = (props) => {
  const { global = globalData, pageData } = props;

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  let headerRef = useRef();

  let headerClassName = classNames(
    "bg-white transition-all duration-[0.5s] ease top-0 left-0 right-0 z-40 flex flex-col justify-center h-main-header",
    { "fixed animation-smooth-scroll": sticky.isSticky }
  );

  const handleScroll = (topOffset, height) => {
    if (topOffset < ANNOUNCEMENT_HEIGHT) {
      setSticky({ isSticky: true, offset: height });
    }
    if (window.scrollY < height + ANNOUNCEMENT_HEIGHT) {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  useEffect(() => {
    const toggleTabIsSticky = () => {
      if (headerRef.current) {
        const header = headerRef.current.getBoundingClientRect();
        handleScroll(header.top, HEADER_HEIGHT);
      }
    };
    window.addEventListener("scroll", toggleTabIsSticky);
    return () => {
      window.removeEventListener("scroll", toggleTabIsSticky);
    };
  }, []);

  return (
    <div style={{ margintop: sticky.offset }}>
      {(pageData?.showAnnouncement &&
        global.attributes.announcements?.length && (
          <Announcement data={global.attributes.announcements} />
        )) ||
        null}
      <header
        style={{ height: HEADER_HEIGHT }}
        ref={headerRef}
        className={headerClassName}
      >
        <div className="container flex flex-row items-center justify-between bg-white">
          <ThriveLogo />
          <div className="flex flex-row items-center">
            <TopNavigation
              data={global.attributes.navbar}
              setSticky={setSticky}
            />
            {/* temporarily remove search icon */}
            {/* <SearchForm /> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default MainHeader;
