import classNames from "classnames";
import { createElement } from "react";
import he from "he";

const Heading = ({ level, text, children, className, ...restProps }) => {
  const headingLevels = {
    1: "h1",
    2: "h2",
    3: "h3",
    4: "h4",
    5: "h5",
    6: "h6",
  };

  let defaultHeadingClassNames = classNames("mt-6 mb-4", {
    "heading-1": level === 1,
    "heading-2": level === 2,
    "heading-3": level === 3,
    "heading-4": level === 4,
    "heading-5": level === 5,
    "heading-6": level === 6,
  });

  // return the selected heading level element  default to h1
  let renderAsElement = level ? headingLevels[`${level}`] : "h1";

  //  heading content - children overrides text prop
  let textContent = children ? children : he.decode(text);

  // override defaultClassName by setting className prop
  let _className = className ? className : defaultHeadingClassNames;

  return createElement(
    renderAsElement,
    { className: _className, ...restProps },
    textContent
  );
};

export default Heading;
