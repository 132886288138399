import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";
import { DARK_LOGO_URI, LIGHT_LOGO_URI } from "assets/svg";

/** Display Thrive Agric Logo, in either `light` or `dark` mode */
const ThriveLogo = (props) => {
  const { light, dark, width, height, altText } = props;
  return (
    <Link href={"/"} className="z-40 font-bold">
      <a>
        <Image
          width={width}
          height={height}
          src={dark ? DARK_LOGO_URI : light && LIGHT_LOGO_URI}
          alt={altText}
          layout="fixed"
        />
      </a>
    </Link>
  );
};

ThriveLogo.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ThriveLogo.defaultProps = {
  width: 140,
  height: 51,
  light: true,
  dark: false,
};

export default ThriveLogo;
