import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import tailwindConfig from "tailwind.config";

const Spinner = ({ size = 20 }) => {
  return (
    <svg
      className="animate-spinner"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        x: "0px",
        y: "0px",
        viewBox: `0 0 ${size} ${size}`,
      }}
    >
      <circle
        style={{
          fill: "transparent",
          stroke: tailwindConfig.theme.extend.colors.white,
          strokeWidth: "2",
          strokeLinecap: "round",
          strokeDasharray: 3.14 * size,
        }}
        cx={size * 0.5}
        cy={size * 0.5}
        r={(size - 2) * 0.5}
      />
    </svg>
  );
};

const Button = (props) => {
  const {
    cta,
    type = "button",
    primary,
    secondary,
    tertiary,
    children,
    transparent,
    className = "",
    textClassName,
    buttonText,
    onClick = () => null,
    disabled = false,
    loading,
  } = props;

  // a neutral button type
  let isDefaultButton = !primary && !secondary && !tertiary && !transparent;
  let isPrimaryButton = !disabled && primary;
  let isSecondaryButton = !disabled && secondary;
  let isTertiaryButton = !disabled && tertiary;
  let isTransparentButton = !disabled && transparent;

  let isDisabledWithType =
    disabled && (primary || secondary || tertiary || transparent);
  let isDisabledWithoutType =
    disabled && (!primary || !secondary || !tertiary || !transparent);

  let isDisabledButton = isDisabledWithType || isDisabledWithoutType;

  const buttonClassName = classNames(
    "color-transition-300 hover-ease-in-out flex flex-row h-12  max-w-fit  w-full py-4 text-center items-center text-white rounded-lg px-4 sm:px-7",
    {
      "bg-primary text-platinum hover:text-white hover:bg-thrive-india-green":
        isDefaultButton,
      "bg-primary hover:bg-thrive-india-green": isPrimaryButton,
      "bg-secondary hover:bg-dark": isSecondaryButton,
      "bg-tertiary hover:text-thrive-onyx hover:bg-thrive-spanish-pink":
        isTertiaryButton,
      "bg-transparent": isTransparentButton,
      "cursor-pointer": !disabled,
      "cursor-not-allowed bg-thrive-sonic-silver hover:bg-thrive-sonic-silver text-thrive-onyx hover:text-thrive-onyx":
        isDisabledButton,
      [`${className}`]: true, // append whatever additional className is added (not recommeded)
    }
  );
  const buttonTextClassName = classNames("mr-3 text-sm font-normal leading-4", {
    // append whatever additional classNames is passed
    [`${textClassName}`]: true,
  });

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      // `mt-4 sm:mt-0 py-3 w-full sm:w-[214px]`
      className={buttonClassName}
    >
      {buttonText ? (
        <>
          <span className={buttonTextClassName}>{buttonText}</span>
          {loading ? (
            <Spinner size={20} />
          ) : (
            (cta && (
              <svg
                width="28"
                height="15"
                viewBox="0 0 28 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.53125 0.848633L7.25 6.84863C7.39583 6.99447 7.46875 7.16113 7.46875 7.34863C7.46875 7.53613 7.39583 7.71322 7.25 7.87988L1.53125 13.8486C1.19792 14.1403 0.84375 14.1507 0.46875 13.8799C0.177083 13.5257 0.177083 13.1715 0.46875 12.8174L5.71875 7.31738L0.46875 1.91113C0.177083 1.53613 0.177083 1.18197 0.46875 0.848633C0.84375 0.556966 1.19792 0.556966 1.53125 0.848633ZM11.5312 0.848633L17.25 6.84863C17.3958 6.99447 17.4688 7.16113 17.4688 7.34863C17.4688 7.53613 17.3958 7.71322 17.25 7.87988L11.5312 13.8486C11.1979 14.1403 10.8438 14.1507 10.4688 13.8799C10.1771 13.5257 10.1771 13.1715 10.4688 12.8174L15.7188 7.31738L10.4688 1.91113C10.1771 1.53613 10.1771 1.18197 10.4688 0.848633C10.8438 0.556966 11.1979 0.556966 11.5312 0.848633ZM21.5312 0.848633L27.25 6.84863C27.3958 6.99447 27.4688 7.16113 27.4688 7.34863C27.4688 7.53613 27.3958 7.71322 27.25 7.87988L21.5312 13.8486C21.1979 14.1403 20.8438 14.1507 20.4688 13.8799C20.1771 13.5257 20.1771 13.1715 20.4688 12.8174L25.7188 7.31738L20.4688 1.91113C20.1771 1.53613 20.1771 1.18197 20.4688 0.848633C20.8438 0.556966 21.1979 0.556966 21.5312 0.848633Z"
                  fill="currentColor"
                />
              </svg>
            )) ||
            null
          )}
        </>
      ) : (
        children
      )}
    </button>
  );
};

Button.propTypes = {
  buttonText: PropTypes.string,
  type: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  transparent: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  textClassName: PropTypes.string,
};

export default Button;
