import RichText from "components/sections/richText/richText";
import React from "react";
import EitherPageLink from "./footerLink";

const FooterBottomElement = ({ data }) => {
  return (
    <div className="flex flex-col items-center justify-between mb-3 sm:flex-row lg:whitespace-nowrap">
      {data?.copyrightText && (
        <RichText data={{ content: data.copyrightText }} />
      )}
      <div className="flex items-center justify-center w-full gap-4 mt-8 text-sm md:justify-end md:mt-0">
        {data?.footerBottomLinks &&
          data?.footerBottomLinks?.map((footerBottomLink, index) => {
            return <EitherPageLink data={footerBottomLink} key={index} />;
          })}
      </div>
    </div>
  );
};

export default FooterBottomElement;
